

.panel {
  transition: all 0.2s ease-in-out;
  width: 50%;
  min-height: calc(100vh - 10rem);
  height:100%;
  padding: 0.5rem;
  border-width: 1px;
  border-color: #003459;
}

.fullscreen-panel {
  z-index: 1000;
  width: 100%;
}

.not-fullscreen-panel {
  width:0%;
  visibility: collapse;
  padding: 0;
  border:none;
  margin:0;
}